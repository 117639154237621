<template>
  <div>
    <Back title="用户协议" />
    <div :class="$style.content">
      NAO艺术平台用户使用条款
      欢迎使用NAO艺术平台！NAO艺术平台是一个允许您购买、销售和展示数字藏品的电商平台。这些使用条款由您与NAO艺术平台（“NAO平台”、“公司”或“我们”）签订并在您和NAO艺术平台之间订立。以下条款和条件，以及它们通过引用明确纳入的任何文件，管理您对
      NAO艺术平台 的访问和使用，任何内容、功能和服务）。
      <br />
      1) 接受这些条款
      当您使用NAO艺术平台购买、出售或展示不可替代的令牌（“数字藏品”）或创建数字藏品的集合（统称为“服务”）时，这些使用条款规定了您的权利和责任，所以请仔细阅读。NAO艺术平台是一个促进买卖双方交易的管理平台，但不是数字藏品买卖双方之间或任何用户之间的任何协议的一方。单击“我接受”或“已同意”按钮、完成帐户注册过程或使用本平台，即表示您接受并同意受这些使用条款以及通过引用并入本文的所有条款的约束和遵守。同意这些条款，即表示您特此证明您已年满18岁。如果您不同意这些使用条款，则不得访问或使用本平台。
      NAO艺术平台保留随时自行决定更改或修改这些使用条款的权利。您同意并理解，在这些使用条款发生任何更改后，通过登录您的帐户、访问或使用本平台，即表示您同意修订后的使用条款以及通过引用纳入其中的所有条款。我们鼓励您不时查看使用条款，以确保您了解访问或使用本平台时适用的条款和条件。
      <br />
      2) 您的 NAO艺术平台帐户
      您需要使用NAO艺术平台创建一个帐户才能使用服务。当您创建帐户时，我们会要求您提供一些有关您自己的信息，我们可能会自行决定要求您提供其他信息和/或文件，如果您未针对此类请求提供完整准确的信息和/或文件，我们可能会拒绝向您提供服务。
      您的 NAO艺术平台 帐户受以下条件的约束：
      <br />
      ·
      您只能在NAO艺术平台上开设一个帐户。如果我们有理由怀疑您在NAO艺术平台上开设了多个账户，我们保留采取我们认为适当的任何措施的权利，包括但不限于关闭和/或冻结您的账户。
      <br />
      ·
      访问：您理解并同意，对您的NAO艺术平台帐户的访问仅限于您本人。您同意，未经我们事先书面许可，您不会将您的NAO艺术平台帐户出售、出租、租赁或授予任何人访问权限。
      <br />
      ·
      安全性：您理解并同意，您全权负责维护您帐户的安全并控制您用于访问服务的任何用户名、密码或任何其他代码。第三方未经授权访问您的帐户可能会导致数字藏品和/或您帐户和任何关联帐户（包括您关联的银行帐户和银行卡）中的资金丢失或被盗。您理解并同意，您不会让我们负责管理和维护您帐户的安全。您进一步理解并同意，对于未经授权访问或使用您的帐户，我们概不负责（您也不会让我们负责）。您有责任监控您的帐户，如果您发现帐户中有任何未经授权或可疑的活动。
      <br />
      ·
      交易费用：通过在NAO艺术平台上买卖数字藏品，您同意支付所有适用的费用，并且您授权NAO艺术平台直接从您的付款中自动扣除费用。
      <br />
      ·
      沟通：您同意并理解我们将通过电子方式与您沟通。为确保您收到我们所有的通讯，您同意保持您的电子邮件地址和电话号码为最新，并在有任何更改时通知我们。您同意发送到您记录在案的电子邮件地址的任何通知、协议、披露或其他通信均被视为有效。
      <br />
      3) 账户暂停 如果我们自行决定怀疑 (1) 您的账户被用于洗钱或任何非法活动；(2)
      您隐瞒或提供虚假身份信息或其他详细信息；(3) 您从事过欺诈活动；(4)
      您以不正当的方式获得了数字藏品（包括但不限于使用被盗资金或支付方式，或在保留或处置数字藏品的同时试图退还您的款项）；(5)
      您正在使用、雇用或操作机器人或其他形式的自动化和/或多个帐户来从事NAO艺术平台上的任何活动；(6)
      作为平台用户您的不正当言行（包括但不限于捏造、散布虚假事实，损害他人名誉、商誉、对平台进行诋毁、造谣及传播未经证实的言论、恶意投诉）造成平台方或平台相关机构及个人遭受利益损害的；(7)
      您以其他方式违反了这些使用条款；基于前述您同意NAO艺术平台有权立即暂停您的账户、暂停或取消您对服务的访问，或关闭您的账户，
      如果 NAO艺术平台 有理由相信您参与了上述 (1)、(2)、(3)、(4) 或 (5)
      项活动，NAO艺术平台保留自行决定没收任何数字藏品的权利根据这些活动进行的购买和/或认为此类交易无效的权利，并且我们有权依据现行法律法规之规定采取合理合法手段维护各位用户之账户安全。
      <br />
      4) 沟通
      您同意我们可以通过电子邮件向您发送促销信息，包括但不限于时事通讯、特别优惠、调查以及我们认为您会感兴趣的其他新闻和信息。
      您同意我们可以通过电子邮件或短信向您发送与NAO艺术平台上数字藏品的购买或销售状态相关的通信，以及与您与平台互动相关的其他通信，包括但不限于关于您有兴趣购买的数字藏品的状态。
      <br />
      5) 用户内容 本平台允许 (1)
      用户创建个人资料，他们可以在其中发布有关自己的某些信息、链接到其他平台并显示他们拥有的
      数字藏品；(2)
      数字藏品的艺术家或创作者发布他们的数字藏品和个人简介（本条（1）、（2）所述内容统称为“用户内容”）。所有用户内容都必须遵守这些使用条款。
      您发布到本平台的任何个人资料信息将被视为非机密和非专有信息。通过在平台上提供任何用户内容，您授予我们和我们的附属公司以及我们各自的被许可人、继承人，并转让使用、复制、修改、执行、展示、分发、转发、发布、广播和以其他方式向第三方披露的权利任何此类材料用于任何目的。您声明并保证
      ：您拥有并控制您的用户内容的所有权利，并有权向我们和我们的附属公司以及我们各自的被许可人、继承人和受让人授予此类许可；您的所有用户内容都将遵守这些使用条款。
      您理解并同意您对您提交或贡献的任何用户内容负责，并且您（而非NAO艺术平台）对此类内容承担全部责任，包括其合法性、可靠性、准确性和适当性。对于您或任何其他用户在本平台上发布的任何用户内容的内容、准确性或适当性，我们不对任何第三方负责。
      如果您是数字藏品的创作者者，您特此授予NAO艺术平台将您的姓名和图像用于营销或促销目的的权利；如果您是数字藏品创作者，您同意我们可以使用或修改您为营销或促销目的创建的数字藏品图像。您还同意我们可以使用您的传记和其他有关您的公开信息来推广您创建的
      数字藏品。
      <br />
      · 作为数字藏品创作者，您理解并同意：
      <br />
      · 您对数字藏品创作的准确性和内容负责；
      <br />
      · 任何违反NAO艺术平台政策的数字藏品可能会由NAO艺术平台自行决定混淆或删除；
      <br />
      · 您将尊重他人的知识产权；
      <br />
      · 您不会与其他数字藏品创作者协调定价。
      我们保留自行决定下架您的数字藏品的权利。尽管我们不需要监控任何用户内容，但我们可以自行决定随时出于任何原因删除内容，恕不另行通知。NAO艺术平台可能会监控内容，以检测和防止欺诈活动或违反这些使用条款的行为。
      <br />
      6) 所有权
      除非我们另有书面说明，本平台、所有内容和其中包含的所有其他材料，包括但不限于NAO艺术平台徽标以及所有设计、文本、图形、图片、信息、数据、软件、声音文件、其他文件及其选择和安排（统称为“NAO艺术平台
      内容”）是
      NAO艺术平台或我们的附属公司、许可方或用户（如适用）的专有财产。NAO艺术平台徽标和任何可能出现在本平台或其他地方的NAO艺术平台
      产品或服务名称、徽标或标语是NAO艺术平台或我们关联公司的商标，不得全部或部分复制、模仿或使用。
      未经我们明确的书面许可，您不得使用任何NAO艺术平台内容链接到本平台或内容。未经我们明确书面同意，您不得使用框架技术来封装任何NAO艺术平台内容。此外，平台和内容的外观和感觉，包括但不限于所有页面标题、自定义图形、按钮图标和脚本，构成ONE
      NF
      的服务标志、商标或商业外观，不得复制、模仿、或在未经我们事先书面许可的情况下全部或部分使用。
      尽管此处有任何相反的规定，您理解并同意您对您的帐户不拥有所有权或其他财产权益。
      <br />
      7) 销售条款
      在NAO艺术平台上或通过NAO艺术平台出价或下订单，即表示您同意提交具有约束力的要约以购买此类数字藏品或服务。购买完成后，您的订单将被接受并确认，NAO艺术平台会显示确认页面（“确认页面”）。您在此明确同意在显示确认页面后立即开始供应数字藏品。
      除法律不能排除或限制的任何法定保证或保证外，不允许退款或退货。
      <br />
      8) 知识产权 在 NAO艺术平台
      内容之外，平台上的所有其他商标、产品名称和徽标均为其各自所有者的财产，未经适用商标持有人许可，不得全部或部分复制、模仿或使用.
      在不限制上述规定的情况下，如果您认为 NAO艺术平台
      托管的第三方材料侵犯了您的版权或商标权，请通过以下电子邮件地址与NAO艺术平台的版权代理联系来提交侵权通知：contact@vsk.tech。
      在这种情况下，请向NAO艺术平台的版权代理提供以下信息： (1)
      授权代表版权所有者行事的人的电子或物理签名；(2)
      对您声称被侵权的版权作品的描述；(3)
      描述您声称侵权的材料在平台上的位置；(4)
      您的地址、电话号码和电子邮件地址；(5)
      您的书面声明，表明您有充分理由相信争议使用未经著作权人、其代理人或法律授权；(6)
      您根据伪证罪作出的声明，声明您通知中的上述信息准确无误，并且您是版权所有者或被授权代表版权所有者行事。
      NAO艺术平台的政策是暂停或终止重复侵权者的帐户。NAO艺术平台的回应可能取决于相关的加重或减轻情节，但通常我们会终止一个帐户，如果它是三个有效的侵权通知的对象。
      <br />
      9) 您对NAO艺术平台的使用
      我们特此授予您访问和使用本平台和内容的有限、非排他性、不可转让、可撤销的许可,我们授予此类许可须符合以下条件：
      <br />
      ·
      非法活动：您同意不参与或协助任何违反任何法律、法规、条例、法规或制裁计划的活动
      ，或涉及任何非法活动的收益。
      <br />
      ·
      滥用活动：您同意不参与任何对NAO艺术平台构成威胁的活动，例如通过传播病毒或其他有害代码，或通过未经授权访问平台或其他用户的帐户。
      <br />
      · 不当行为：您同意不干扰其他用户访问或使用服务。
      <br />
      · 通信：您同意不与其他用户通信以 (1)
      发送未经请求的广告或促销、捐赠请求或垃圾邮件；(2)
      发表仇恨言论或骚扰、辱骂其他用户的；(3)
      干扰其他用户的交易。您同意不出于任何目的使用从本平台收集的数据与个人、公司或其他个人或实体联系，包括但不限于营销活动。
      <br />
      ·
      欺诈：您同意不从事任何旨在欺诈NAO艺术平台、其他用户或任何其他人的活动；或向NAO艺术平台提供任何虚假、不准确或误导性的信息。
      <br />
      ·
      赌博：您同意不利用服务参与任何彩票、竞标费拍卖、竞赛、抽奖或其他机会游戏。
      <br />
      ·
      税费：NAO艺术平台或任何其他NAO艺术平台方均不负责确定适用于您的预扣税、销售税、使用税、增值税、转让税或其他税，以及与之相关的任何利息和罚款（“税费”）。您同意，您全权负责确定适用于您的数字藏品交易的税款（如果有），并向适当的税务机关预扣、收取、报告和汇出正确的税款。
      <br />
      ·
      知识产权侵权：(1)您同意不从事涉及侵犯或违反任何版权、商标、公开权或隐私权或任何其他法律规定的专有权利的项目的交易；(2)
      未经NAO艺术平台明确书面同意，使用NAO艺术平台内容（定义见上文第 8 节）；(3)
      从事任何暗示对NAO艺术平台的不真实认可或从属关系的行为。 ·
      超链接：特此授予您有限的、非排他性的、不可转让的、可撤销的许可，以出于非商业目的创建指向本平台的文本超链接，前提是此类链接不得以虚假方式描绘NAO艺术平台、我们的附属公司或服务。误导、贬损或其他诽谤方式，并进一步规定链接站点不包含任何成人或非法材料，或其他具有攻击性、骚扰性或其他令人反感的材料。
      <br />
      ·
      附加禁令：(1)您同意不分发、出版、广播、复制、复制、转发或公开展示任何内容；(2)
      修改或创建本平台或内容或其任何部分的衍生作品；(3)
      在平台或内容上使用任何数据挖掘、机器人或类似的数据收集或提取方法；(4)
      下载本平台或内容的任何部分，但我们明确允许的情况除外，用于页面缓存的目的除外。
      如果您不确定预期的使用是否会违反这些使用条款，请通过下面联系信息下列出的地址与我们联系。
      <br />
      10) 意外转移
      您理解并同意，如果您将数字藏品存入错误地址，我们可能没有能力、也没有义务或义务将此类数字藏品退还给您。无论我们是否控制目标地址，本节都适用。
      请注意：本节的例外情况可能会根据具体情况由我们自行决定。
      <br />
      11) 隐私 您承认并同意根据 NAO艺术平台
      的隐私政策收集、使用和披露您的个人信息，该政策通过引用并入本用户协议。
      <br />
      12) 修改
      您同意并理解我们可能会修改部分或全部NAO艺术平台或服务，恕不另行通知。
      <br />
      13) 风险
      请注意访问或使用NAO艺术平台的以下风险：包括数字藏品在内的区块链资产的价格和流动性极易波动，可能会出现较大波动；中国或国际层面的立法和监管变化或行动可能会对数字藏品的使用、转让、交换和价值产生不利影响；数字藏品不是法定货币，也不受政府支持；数字藏品中的交易可能是不可逆转的，因此，由于欺诈或意外交易造成的损失可能无法挽回；数字藏品中的某些交易在记录在公共分类账上时应被视为进行，这不一定是您发起交易的日期或时间；数字藏品的价值可能源于市场参与者持续愿意为数字藏品交换法定货币，如果该数字藏品的市场消失，这可能导致特定数字藏品的价值永久和全部损失；数字藏品的性质可能会导致欺诈或网络攻击的风险增加，并可能意味着遇到的技术困难可能会阻止访问或使用您的数字藏品；对第三方平台的更改（在下文第
      14 节中确认）可能会产生风险，您访问和使用本平台将受到影响。
      您同意并理解，您全权负责为自己确定这些风险的性质、潜在价值、适用性和适当性，并且
      NAO艺术平台不会就数字藏品提供建议或建议，包括投资策略的适用性和适当性。您同意并理解访问和使用NAO艺术平台的风险由您自己承担。您同意并理解NAO艺术平台不对您在使用数字藏品时可能遇到的任何通信故障、中断、错误、扭曲或延迟负责，无论其原因是什么造成的。
      <br />
      14) 第三方
      NAO艺术平台可能依赖第三方平台，包括但不限于使用微信、支付宝等来执行数字藏品交易。我们的平台可能包含第三方平台或应用程序（统称为“第三方平台”）的链接。NAO艺术平台不拥有或控制第三方站点。您理解并同意，您对任何第三方平台的使用受该第三方平台提供的任何使用条款和/或隐私政策的约束。NAO艺术平台不是任何此类协议的一方。您应查看此类第三方平台提供的任何使用条款和/或隐私政策，并应在与任何第三方进行任何交易之前进行任何您认为必要或适当的调查。
      NAO艺术平台提供这些第三方站点只是为了方便起见，不对第三方站点或其产品或服务进行审查、批准、监控、认可、保证或作出任何陈述。您使用第三方平台中的所有链接的风险由您自己承担。当您离开我们的平台时，我们的协议和政策不再适用。我们可能但没有义务，警告您正在离开我们的平台。
      我们平台上的某些交易可能由第三方平台促进和运行。任何此类交易均受第三方平台服务条款和隐私政策的约束。
      <br />
      15) 免责声明
      除非NAO艺术平台以书面形式明确提供相反的规定，否则本平台、其中包含的内容和其中列出的均按“原样”和“可用”的基础提供，不附带任何保证或限制条件默示。NAO艺术平台（及其供应商）不保证本平台将
      (1) 满足您的要求；(2) 在不间断、及时、安全或无错误的基础上可用；或 (3)
      准确、可靠、完整、合法或安全。
      对于因依赖本平台包含的材料或信息而采取或采取的任何行动而造成的任何形式的损失，我们概不负责。NAO艺术平台不声明或保证平台上的内容准确、完整、可靠、最新或无错误。
      虽然 NAO艺术平台
      尝试让您安全地访问和使用本平台和内容，但NAO艺术平台不能也不代表或保证本平台和内容，我们平台或我们的服务器上列出的任何数字藏品不受其他软件的限制。我们无法保证您在线披露的任何数据的安全性。您接受通过Internet
      提供信息和在线交易的固有安全风险，并且不会让我们对任何安全漏洞负责，除非是由于我们的严重疏忽。
      我们不对您的任何损失负责，也不对您使用数字藏品的任何用途负责，包括但不限于由以下原因引起的任何损失、损害或索赔：(1)
      用户错误例如忘记密码、错误构建的交易或输入错误的地址；(2)
      服务器故障或数据丢失；(3) 损坏的钱包文件；(4) 未经授权访问应用程序；(5)
      任何未经授权的第三方活动，包括但不限于使用病毒、网络钓鱼、暴力破解或其他攻击平台或小工具的手段。
      NAO艺术平台
      不对由于漏洞或任何类型的故障、软件（例如钱包、智能合约）、区块链或数字藏品的任何其他功能的异常行为而造成的持续损失负责。NAO艺术平台不对由于开发人员或代表延迟报告支持数字藏品的区块链的任何问题（或根本没有报告）而造成的持续损失负责，包括技术节点问题或任何其他导致资金损失的问题。
      没有本协议中的条款应排除或限制任何一方因疏忽、违反法律或任何其他无法通过合法手段限制或排除的活动造成的欺诈、死亡或人身伤害的责任。
      在法律规定的最大范围内，NAO艺术平台特此拒绝任何形式的保证，无论是明示或暗示的、法定的或其他形式的，包括但不限于任何关于适销性和适销性的保证到站点和其中包含的内容。
      上述规定不影响根据适用法律不能排除或限制的任何保证。
      <br />
      16) 责任限制
      在法律允许的最大范围内，在任何情况下，NAO艺术平台均不对您或任何第三方的任何利润损失或任何间接的、后果性的、示范性的、偶然的、特殊的或惩罚性的产品损害承担责任，或由此产生的产品损害第三方平台和产品，或与收入损失、利润损失、业务损失或预期储蓄、使用损失、商誉损失或数据丢失相关的任何损害，以及是否由侵权行为引起（包括）违反合同，或以其他方式，即使可以预见，并且即使NAO艺术平台已被告知此类损害的可能性。访问和使用平台、产品或第三方平台和产品由您自行决定并承担风险。
      尽管有任何相反的规定，在任何情况下，NAO艺术平台均不承担因本协议、访问和使用本平台产品、内容或任何相关的或以任何方式与本协议相关的最大累计责任在平台上超过
      (A) 100 元人民币或 (B)
      您在过去六个月内为服务支付给NAO艺术平台的金额，由此产生的责任。前述责任限制不适用于责任NAO艺术平台用于
      (A) 因 NAO艺术平台成员的疏忽而造成的死亡或人身伤害；或 (B)
      因NAO艺术平台的欺诈或欺诈性虚假陈述的成员造成的任何伤害。
      某些司法管辖区不允许排除或限制偶然或间接损害，因此上述限制或排除可能不适用于您。某些司法管辖区还限制对消费品造成的人身伤害的免责声明或责任限制，因此该限制可能不适用于人身伤害索赔。
      <br />
      17) 赔偿
      您进一步同意NAO艺术平台各方应控制任何第三方索赔的辩护或和解，此赔偿是对您与NAO艺术平台之间的书面协议中规定的任何其他赔偿的补充而非替代。
      <br />
      18) 管辖法律
      这些使用条款、您对NAO艺术平台的使用、您的权利和义务，以及由这些使用条款引起的或与之相关的所有行动均应受中国法律的管辖，就好像这些使用条款一样是在中国完全签订和完全履行的合同。您理解并同意，您按照这些使用条款的规定使用NAO艺术平台应被视为发生在中国，并受中国法律的约束，无论其是否存在法律冲突。
      <br />
      19) 终止
      我们保留随时以任何理由自行决定终止您访问或使用本平台或内容的许可的权利，恕不另行通知。您理解并同意，在这种情况下，我们对您不承担任何责任或义务。
      <br />
      20) 可分割性
      如果这些使用条款的任何条款或规定无效或不可执行，则该条款或规定应与这些使用条款分开，并且不会影响该条款任何剩余部分的有效性或可执行性。
      这些使用条款构成您与NAO艺术平台之间关于您访问和使用平台和内容的完整协议，并取代任何和所有先前的讨论协议以及任何类型的理解（包括但不限于本用户协议的先前版本）。除非本文另有规定，否则这些使用条款仅旨在为各方谋取利益，无意将第三方受益权授予任何其他人或实体。
      <br />
      21) 联系方式 如果您有任何问题、想提供反馈或想了解有关 NAO艺术平台
      的更多信息，请随时发送电子邮件至
      contact@vsk.tech。如果您想提出投诉，请通过contact@vsk.tech与我们联系。
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
export default {
  components: {
    Back,
  },
};
</script>

<style lang="scss" module>
.content {
  padding: 20px;
  line-height: 1.5;
}
</style>
